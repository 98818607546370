import * as React from "react"
const Star1 = ( props ) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={ 10 }
        height={ 12 }
        fill="none"
        { ...props }
    >
        <path
            fill="white"
            fillOpacity={ 0.2 }
            d="M7.944 9.87c.196 1.043-.898 1.875-1.532 1.165L4.905 9.348a.743.743 0 0 0-.718-.243l-2.393.498c-.781.163-1.264-.854-.783-1.652l1.47-2.443c.165-.273.227-.61.169-.917l-.468-2.478C1.985 1.07 3.079.237 3.713.947L5.22 2.635c.187.21.45.298.718.243l2.393-.498c.782-.163 1.264.854.784 1.652L7.644 6.474c-.165.274-.227.61-.168.917l.468 2.479Z"
        />
    </svg>
)
export default Star1
