export default function SecurityCard ( props )
{
    return (
        <div className="bg-[#fcfbfb] border border-gray-200 rounded-3xl h-40 sm:h-64 lm:h-60 tab:h-60 flex p-6 w-[96%] sm:w-full lm:w-full card5 sm:flex-col lm:flex-col tab:flex-col">
            <div className="w-[15%] sm:w-full lm:w-full">
                <div className="bg-white rounded-full w-12 h-12 flex justify-center items-center my-4 mx-2 shadow-md">
                    <img src={ props.icon } alt="Lock" />
                </div>
            </div>
            <div className="w-[85%] sm:w-full lm:w-full">
                <h6 className="text-lg font-semibold pb-3">{ props.heading }</h6>
                <div
                    className="text-gray-700 text-sm"
                    dangerouslySetInnerHTML={ { __html: props.text } }
                />
            </div>
        </div>
    )
}