export default function ReviewCard (props)
{
    return (
        <div className="w-11/12 h-72 sl:h-80 mx-auto p-4 bg-white rounded-2xl flex flex-col items-center justify-center p-6 shadow-lg">
            <div className="w-full text-base h-1/5 s font-medium flex">
                <div className="w-1/4 flex relative lm:w-[12%] tab:w-[12%]">
                    <img src={ props.image } className="w-12 h-12 rounded-full border border-white shadow-shadowDpImage" alt="" />
                </div>
                <div className="w-3/4 lm:w-[82%] tab:w-[82%]">
                    <div className="text-md	font-semibold">{props.name}</div>
                    <div className="text-sm font-medium">{props.desg}</div>
                </div>
            </div>
            <div className="w-full text-sm sm:text-base lm:text-base tab:text-base h-4/5 pt-8 leading-relaxed font-medium">{props.review}</div>
        </div>
    )
}