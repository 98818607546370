import * as React from "react"

const Logo = ( props ) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        id="Layer_1"
        data-name="Layer 1"
        viewBox="0 0 2000 1500"
        width={props.width}
        height={props.height}
    >
        <defs>
            <linearGradient
                id="linear-gradient"
                x1={ 191.01 }
                x2={ 493.45 }
                y1={ 948.74 }
                y2={ 573.39 }
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={ 0 } stopColor="#09fcdc" />
                <stop offset={ 1 } stopColor="#ff3fe3" />
            </linearGradient>
            <linearGradient
                id="linear-gradient-2"
                x1={ 665.55 }
                x2={ 1884.9 }
                y1={ 867.8 }
                y2={ 867.8 }
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={ 0.01 } stopColor="#ff3fe3" />
                <stop offset={ 0.98 } stopColor="#09fcdc" />
            </linearGradient>
            <linearGradient
                xlinkHref="#linear-gradient-2"
                id="linear-gradient-3"
                x1={ 665.55 }
                x2={ 1884.9 }
            />
            <linearGradient
                xlinkHref="#linear-gradient-2"
                id="linear-gradient-4"
                x1={ 665.55 }
                x2={ 1884.9 }
            />
            <linearGradient
                xlinkHref="#linear-gradient-2"
                id="linear-gradient-5"
                x1={ 665.55 }
                x2={ 1884.9 }
            />
            <linearGradient
                xlinkHref="#linear-gradient-2"
                id="linear-gradient-6"
                x1={ 665.55 }
                x2={ 1884.9 }
            />
            <linearGradient
                xlinkHref="#linear-gradient-2"
                id="linear-gradient-7"
                x1={ 665.55 }
                x2={ 1884.9 }
            />
            <linearGradient
                xlinkHref="#linear-gradient-2"
                id="linear-gradient-8"
                x1={ 665.55 }
                x2={ 1884.9 }
            />
            <linearGradient
                xlinkHref="#linear-gradient-2"
                id="linear-gradient-9"
                x1={ 665.55 }
                x2={ 1884.9 }
            />
            <linearGradient
                xlinkHref="#linear-gradient-2"
                id="linear-gradient-10"
                x1={ 665.55 }
                x2={ 1884.9 }
            />
            <linearGradient
                xlinkHref="#linear-gradient-2"
                id="linear-gradient-11"
                x1={ 665.55 }
                x2={ 1884.9 }
            />
            <linearGradient
                xlinkHref="#linear-gradient-2"
                id="linear-gradient-12"
                x1={ 665.55 }
                x2={ 1884.9 }
            />
            <linearGradient
                xlinkHref="#linear-gradient-2"
                id="linear-gradient-13"
                x1={ 665.55 }
                x2={ 1884.9 }
            />
            <linearGradient
                xlinkHref="#linear-gradient-2"
                id="linear-gradient-14"
                x1={ 665.55 }
                x2={ 1884.9 }
            />
            <linearGradient
                xlinkHref="#linear-gradient-2"
                id="linear-gradient-15"
                x1={ 665.55 }
                x2={ 1884.9 }
            />
            <linearGradient
                xlinkHref="#linear-gradient-2"
                id="linear-gradient-16"
                x1={ 665.55 }
                x2={ 1884.9 }
            />
            <linearGradient
                xlinkHref="#linear-gradient-2"
                id="linear-gradient-17"
                x1={ 665.55 }
                x2={ 1884.9 }
            />
            <linearGradient
                xlinkHref="#linear-gradient-2"
                id="linear-gradient-18"
                x1={ 665.55 }
                x2={ 1884.9 }
            />
            <linearGradient
                xlinkHref="#linear-gradient-2"
                id="linear-gradient-19"
                x1={ 665.55 }
                x2={ 1884.9 }
            />
            <linearGradient
                xlinkHref="#linear-gradient-2"
                id="linear-gradient-20"
                x1={ 665.55 }
                x2={ 1884.9 }
            />
            <linearGradient
                xlinkHref="#linear-gradient-2"
                id="linear-gradient-21"
                x1={ 665.55 }
                x2={ 1884.9 }
            />
            <linearGradient
                xlinkHref="#linear-gradient-2"
                id="linear-gradient-22"
                x1={ 665.55 }
                x2={ 1884.9 }
            />
            <linearGradient
                xlinkHref="#linear-gradient-2"
                id="linear-gradient-23"
                x1={ 665.55 }
                x2={ 1884.9 }
            />
            <linearGradient
                xlinkHref="#linear-gradient-2"
                id="linear-gradient-24"
                x1={ 665.55 }
                x2={ 1884.9 }
            />
            <linearGradient
                xlinkHref="#linear-gradient-2"
                id="linear-gradient-25"
                x1={ 665.55 }
                x2={ 1884.9 }
            />
            <linearGradient
                xlinkHref="#linear-gradient-2"
                id="linear-gradient-26"
                x1={ 665.55 }
                x2={ 1884.9 }
            />
        </defs>
        <circle
            cx={ 351.14 }
            cy={ 750 }
            r={ 238.98 }
            style={ {
                fill: "url(#linear-gradient)",
            } }
        />
        <path
            d="M481.97 762.88v25.05h-20.93v38.03h-10.62v36.57h-66.61v16.33h-14.62v15.53h-17.13v22.04H329.9v-37.57h-14.62v-16.33h-66.43v-36.57h10.64v25.14h15.1v-25.45h14.47v-30.1h13.11v-15.78h13.11V764h14.62v-41.78h-14.41v-43.04h-14.21V663.4H249v51.45h38.6v7.37h14.57v13.47h-27.21v13.48H261.5v-13.48h-13.08v13.48h-8.45v13.71h-9v13.48h29.91v11.56h-12.03v15.15h17.14l9.61 11.44h-26.75v11.45h-10.63v-38.04h-17.27v-25.04h8.83v-8.49l5.9-5.22h2.54v-8.26l4.68-5.22v-13.47h-4.68V663.4h-5.87v-8.57h-7.28v-7.29h-4.75V631.7h46.59v-13.27h-18.96v-13.28h44.35v-21.58h59.76v8.36h59.75v13.22h28v13.28h24.32v13.27h15.18v15.84h-4.76v7.29h-7.29v8.57h-6.21v58.82h-4.68v13.47l4.68 5.22v8.26h4.28l5.9 5.22v8.49h-10.19v-13.71h-10.19v-13.48h-14.33v13.48h-13.47v-13.48h-14.53v-13.47h14.53v-7.37h27.21V663.4h-51.82v15.78h-15.35v56.51h-14.74v44.08h15.46v58.3h10.81v13.03h45.16v-25.14h10.63v-11.45h-26.74l9.61-11.44h17.13v-15.14H438.4v-11.57h32.83v-13.48h10.74z"
            style={ {
                fill: "#000e0d",
            } }
        />
        <path
            d="M689.63 788.82v-21.05h-21.29v-26.73h26.02v21.29h59.14v-36.67h-63.87v-21.05h-21.29V657.3h21.29v-21.05h68.6v21.05h21.53v26.5H753.5v-20.82h-59.14v36.43h63.87v20.58h21.53v47.79h-21.53v21.05h-68.6ZM808.38 829.5V699.39h21.29V678.1h68.6v21.29h21.53v68.37h-21.53v20.82H834.4v40.93h-26.02Zm26.02-67.18h59.14V704.6H834.4v57.72ZM969.72 788.82v-21.05h-21.29V699.4h21.29v-21.29h68.6v21.05h21.53v63.16h21.29v26.5h-26.02v-21.05h-16.8v21.05h-68.6Zm4.73-26.5h59.14V704.6h-59.14v57.72ZM1119.23 788.82v-21.05h-21.29V699.4h21.29v-21.29h68.6v21.29h21.53v26.26h-26.26v-21.05h-59.14v57.72h59.14v-21.29h26.26v26.73h-21.53v21.05h-68.6ZM1252.18 788.82v-21.05h-21.29V699.4h21.29v-21.29h68.6v21.29h21.53v26.26h-26.26v-21.05h-59.14v15.38h42.58v26.73h-42.58v15.61h59.14V740.8h26.26v26.73h-21.53v21.29h-68.6ZM1460.83 788.82V662.97h-16.56v20.82h-26.02v-26.5h21.29v-21.05h68.6v21.05h21.53v26.5h-26.26v-20.82h-16.56v125.85h-26.02ZM1544.1 788.82V678.11h26.02v84.22h38.09v-84.22H1634v84.22h38.09v-84.22h25.79v89.66h-21.05v21.05h-47.55v-21.05h-16.56v21.05h-68.6ZM1726.5 662.96v-26.73h26.02v26.73h-26.02Zm0 125.85V678.1h26.02v110.71h-26.02ZM1776.41 788.82V678.11h89.9v21.29h21.53v89.42h-26.26V704.6h-59.14v84.22h-26.02Z"
            className="cls-9"
            style={ {
                fill: props.fill,
            } }
        />
        <path
            d="M668.09 857.44h11.76v29.35h8.62v-29.35h11.73v-8.64h-32.11v8.64z"
            style={ {
                fill: "url(#linear-gradient-2)",
            } }
        />
        <path
            d="M737.12 863.47h-14.84V848.8h-8.62v37.99h8.62v-14.67h14.84v14.67h8.65V848.8h-8.65v14.67z"
            style={ {
                fill: "url(#linear-gradient-3)",
            } }
        />
        <path
            d="M759.2 886.79h29.18v-8.64h-20.54v-6.03h20.54v-8.65h-20.54v-6.03h20.54v-8.64H759.2v37.99z"
            style={ {
                fill: "url(#linear-gradient-4)",
            } }
        />
        <path
            d="M820.95 886.79h8.64v-29.35h14.84v29.35h8.63V848.8h-32.11v37.99z"
            style={ {
                fill: "url(#linear-gradient-5)",
            } }
        />
        <path
            d="M866.51 886.79h29.17v-8.64h-20.55v-6.03h20.55v-8.65h-20.55v-6.03h20.55v-8.64h-29.17v37.99z"
            style={ {
                fill: "url(#linear-gradient-6)",
            } }
        />
        <path
            d="M938.48 878.15h-6.05V848.8h-8.62v29.35h-6.06V848.8h-8.62v37.99h37.97V848.8h-8.62v29.35z"
            style={ {
                fill: "url(#linear-gradient-7)",
            } }
        />
        <path
            d="M979.69 886.79h29.17v-8.64h-20.55v-20.71h20.55v-8.64h-29.17v37.99z"
            style={ {
                fill: "url(#linear-gradient-8)",
            } }
        />
        <path
            d="M1022.29 886.79h32.13v-38h-32.13v38Zm8.64-29.35h14.84v20.71h-14.84v-20.71Z"
            style={ {
                fill: "url(#linear-gradient-9)",
            } }
        />
        <path
            d="M1067.85 886.79h8.62v-29.35h14.87v29.35h8.62V848.8h-32.11v37.99z"
            style={ {
                fill: "url(#linear-gradient-10)",
            } }
        />
        <path
            d="M1113.39 886.79h29.19v-8.64h-20.54v-20.71h20.54v-8.64h-29.19v37.99z"
            style={ {
                fill: "url(#linear-gradient-11)",
            } }
        />
        <path
            d="M1156.01 886.79h29.19v-8.64h-20.55v-6.03h20.55v-8.65h-20.55v-6.03h20.55v-8.64h-29.19v37.99z"
            style={ {
                fill: "url(#linear-gradient-12)",
            } }
        />
        <path
            d="M1198.63 886.79h8.64v-14.68h23.49v-23.32h-32.13v38Zm8.64-29.35h14.84v6.03h-14.84v-6.03Z"
            style={ {
                fill: "url(#linear-gradient-13)",
            } }
        />
        <path
            d="M1244.19 857.44h11.73v29.35h8.65v-29.35h11.73v-8.64h-32.11v8.64z"
            style={ {
                fill: "url(#linear-gradient-14)",
            } }
        />
        <path
            d="M1308.86 886.79h32.13v-38h-32.13v38Zm8.64-29.35h14.84v20.71h-14.84v-20.71Z"
            style={ {
                fill: "url(#linear-gradient-15)",
            } }
        />
        <path
            d="M1354.43 886.79h8.62v-14.67h20.56v-8.65h-20.56v-6.03h20.56v-8.64h-29.18v37.99z"
            style={ {
                fill: "url(#linear-gradient-16)",
            } }
        />
        <path
            d="M1448.29 872.12V848.8h-32.11v38h8.62v-14.97l14.97 14.97h12.17l-14.68-14.68h11.03Zm-23.49-14.68h14.86v6.03h-14.86v-6.03Z"
            style={ {
                fill: "url(#linear-gradient-17)",
            } }
        />
        <path
            d="M1461.72 886.79h29.18v-8.64h-20.54v-6.03h20.54v-8.65h-20.54v-6.03h20.54v-8.64h-29.18v37.99z"
            style={ {
                fill: "url(#linear-gradient-18)",
            } }
        />
        <path
            d="M1504.34 886.79h8.64v-29.35h6.03v29.35h8.65v-29.35h6.03v29.35h8.64V848.8h-37.99v37.99z"
            style={ {
                fill: "url(#linear-gradient-19)",
            } }
        />
        <path
            d="M1555.76 886.79h32.11v-38h-32.11v38Zm8.64-29.35h14.84v20.71h-14.84v-20.71Z"
            style={ {
                fill: "url(#linear-gradient-20)",
            } }
        />
        <path
            d="M1601.33 857.44h11.73v29.35h8.62v-29.35h11.76v-8.64h-32.11v8.64z"
            style={ {
                fill: "url(#linear-gradient-21)",
            } }
        />
        <path
            d="M1646.87 886.79h29.18v-8.64h-20.54v-6.03h20.54v-8.65h-20.54v-6.03h20.54v-8.64h-29.18v37.99z"
            style={ {
                fill: "url(#linear-gradient-22)",
            } }
        />
        <path
            d="M1737.97 878.15h-6.03V848.8h-8.65v29.35h-6.03V848.8h-8.64v37.99h37.99V848.8h-8.64v29.35z"
            style={ {
                fill: "url(#linear-gradient-23)",
            } }
        />
        <path
            d="M1760.05 886.79h32.11v-38h-32.11v38Zm8.62-29.35h14.86v20.71h-14.86v-20.71Z"
            style={ {
                fill: "url(#linear-gradient-24)",
            } }
        />
        <path
            d="M1837.72 872.12V848.8h-32.13v38h8.64v-14.97l14.97 14.97h12.15l-14.68-14.68h11.05Zm-23.49-14.68h14.84v6.03h-14.84v-6.03Z"
            style={ {
                fill: "url(#linear-gradient-25)",
            } }
        />
        <path
            d="M1886.91 848.8h-12.17l-14.97 14.96V848.8h-8.62v37.99h8.62v-14.96l14.97 14.96h12.17l-18.99-19.01 18.99-18.98z"
            style={ {
                fill: "url(#linear-gradient-26)",
            } }
        />
    </svg>
)
export default Logo
