import React, { useEffect, useState } from 'react';
import Navbar from "../Common/Navbar";
import { primary } from "../Assets/Colors";
import useWindowSize from '../Common/WindowSize';
import { useTranslation } from "react-i18next";
import Footer from '../Common/Footer';

export default function Terms ()
{
    const size = useWindowSize();
    const { i18n, t } = useTranslation();
    const [ lang, setLang ] = useState( i18n.language );

    useEffect( () =>
    {
        const storedLang = localStorage.getItem( "lang" ) || "ar";
        console.log( "Stored Language:", storedLang );
        i18n.changeLanguage( storedLang );
        setLang( storedLang );
        document.documentElement.dir = storedLang === "ar" ? "rtl" : "ltr";
        console.log( "Document Direction:", document.documentElement.dir );
    }, [ i18n, lang ] );

    return (
        <div className="w-full">
            <Navbar bg="bg-white" text="text-black" logoFill="#333" btnbg={ primary } btntext="white" />
            <section className="relative mx-auto pt-20">
                <div className="relative z-[49] mx-auto max-w-[65rem] w-full px-12 pt-8 sm:p-8 lm:py-16">
                    <h6 className="text-5xl font-semibold">{ t( "Terms of Service" ) }</h6>
                    <h6 className="mt-4">{ t( "Effective September 01, 2024" ) }</h6>
                    <p className="mt-4">{ t( "Welcome to SpaceTwin! By accessing or using our website, www.spacetwin.sa, you agree to comply with and be bound by the following Terms of Service. If you do not agree to these terms, please do not use our website." ) }</p>

                    <h6 className="mt-6 text-2xl font-semibold">{ t( "1. Acceptance of Terms" ) }</h6>
                    <p className="mt-4">{ t( "By accessing or using the SpaceTwin website, you agree to these Terms of Service, our Privacy Policy, and any other terms and conditions that may apply. If you do not agree, you must not use our website." ) }</p>

                    <h6 className="mt-6 text-2xl font-semibold">{ t( "2. Changes to Terms" ) }</h6>
                    <p className="mt-4">{ t( "We reserve the right to modify or update these Terms of Service at any time. Any changes will be effective immediately upon posting on this page. Your continued use of the website after any changes signifies your acceptance of the updated terms. Please check this page regularly to stay informed of any updates." ) }</p>

                    <h6 className="mt-6 text-2xl font-semibold">{ t( "3. Use of Our Website" ) }</h6>
                    <p className="mt-4">{ t( "You agree to use the SpaceTwin website in accordance with these Terms of Service and all applicable laws and regulations. You must not use the website:" ) }</p>
                    <ol className='ms-10'>
                        <li className="list-decimal">
                            <p className="mt-2">{ t( "For any unlawful purpose or to promote illegal activities. To post or transmit any content that is defamatory, obscene, harmful, or otherwise objectionable." ) }</p>
                        </li>
                        <li className="list-decimal">
                            <p>{ t( "To impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity." ) }</p>
                        </li>
                        <li className="list-decimal">
                            <p>{ t( "To distribute spam, chain letters, or other unsolicited communications." ) }</p>
                        </li>
                        <li className="list-decimal">
                            <p>{ t( "To interfere with or disrupt the website or servers or networks connected to the website." ) }</p>
                        </li>
                    </ol>

                    <h6 className="mt-6 text-2xl font-semibold">{ t( "4. User Accounts" ) }</h6>
                    <p className="mt-4">{ t( "To access certain features of our website, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information, including your password, and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security." ) }</p>

                    <h6 className="mt-6 text-2xl font-semibold">{ t( "5. Content and Intellectual Property" ) }</h6>
                    <p className="mt-4">{ t( "All content on the SpaceTwin website, including text, graphics, logos, images, and software, is the property of SpaceTwin or its content suppliers and is protected by intellectual property laws. You agree not to reproduce, distribute, modify, create derivative works from, publicly display, or exploit any content from the website without our express written permission." ) }</p>

                    <h6 className="mt-6 text-2xl font-semibold">{ t( "6. Termination" ) }</h6>
                    <p className="mt-4">{ t( "We reserve the right to suspend or terminate your access to the website at our discretion, without notice, for conduct that we believe violates these Terms of Service or is harmful to other users, us, or third parties, or for any other reason." ) }</p>

                    <h6 className="mt-6 text-2xl font-semibold">{ t( "7. Disclaimer of Warranties" ) }</h6>
                    <p className="mt-4">{ t( "The SpaceTwin website is provided on an 'as is' and 'as available' basis. We make no warranties, expressed or implied, regarding the website, including but not limited to its availability, reliability, or suitability for any particular purpose." ) }</p>

                    <h6 className="mt-6 text-2xl font-semibold">{ t( "8. Limitation of Liability" ) }</h6>
                    <p className="mt-4">{ t( "To the fullest extent permitted by law, SpaceTwin and its affiliates, officers, directors, employees, and agents shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising from or related to your use of or inability to use the website, even if we have been advised of the possibility of such damages." ) }</p>

                    <h6 className="mt-6 text-2xl font-semibold">{ t( "9. Governing Law" ) }</h6>
                    <p className="mt-4">{ t( "These Terms of Service are governed by and construed in accordance with the laws of Saudi Arabia, without regard to its conflict of law principles. Any legal action or proceeding related to your use of the website must be brought in the courts located in Saudi Arabia." ) }</p>

                    <h6 className="mt-6 text-2xl font-semibold">{ t( "10. Contact Information" ) }</h6>
                    <p className="mt-4">{ t( "If you have any questions about these Terms of Service, please contact us at support@spacetwin.sa." ) }</p>

                    <p className="mt-4">{ t( "Thank you for choosing SpaceTwin!" ) }</p>

                    <br />
                    <br />
                </div>
            </section>
            <Footer />
        </div>
    )
}