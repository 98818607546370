export default function Button (props)
{
    const buttonStyle = {
        height: props.height,
        width: props.width,
        // background: props.background,
        // color: props.color,
        fontWeight: props.fontWeight || 600,
        border:props.border
    };

    return (
        <button className="text-sm sm:text-base sm:p-2 rounded-full flex items-center justify-center bg-gradient-45 from-bg-gradient-start text-black to-bg-gradient-end" style={ buttonStyle } onClick={props.onClick}>
            { props.icon && <span style={ { marginRight: '8px' } }><img src={props.icon} width="20" height="20"/></span> }
            { props.text }
        </button>
    )
}