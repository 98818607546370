import * as React from "react"
const Star4 = ( props ) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={ 10 }
        height={ 12 }
        fill="none"
        { ...props }
    >
        <path
            fill="white"
            fillOpacity={ 0.2 }
            d="M4.902 1.294c.468-.848 1.742-.29 1.767.774l.05 2.204c.008.31.138.62.357.849L8.89 7.014c.632.66.382 1.737-.41 1.771l-2.277.1a.768.768 0 0 0-.648.408l-.97 1.757c-.467.847-1.741.288-1.766-.775l-.05-2.204a1.288 1.288 0 0 0-.357-.848L.598 5.329c-.632-.66-.383-1.736.41-1.77l2.277-.1a.769.769 0 0 0 .648-.409l.97-1.756Z"
        />
    </svg>
)
export default Star4