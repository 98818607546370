import RightArrow from "../Assets/svgs/RightArrow"
import { primary } from "../Assets/Colors";
import { useTranslation } from "react-i18next";

export default function BlogCard (props)
{
    const { i18n, t } = useTranslation();

    return (
        <div className="w-11/12 mx-auto h-84 lm:h-120 bg-white rounded-2xl flex flex-col items-center justify-center">
            <div className="w-full text-base h-3/5 sm:h-[70%] lm:h-[70%] font-medium">
                <img className="rounded-t-2xl sm:h-full lm:h-full tab:h-[90%] sm:w-full lm:w-full" src={ props.image } alt="image" />
            </div>
            <div className="w-full text-base h-2/5 sm:h-[30%] lm:h-[30%] font-medium flex flex-col items-start pl-10 justify-around p-4">
                <div className="text-lg sm:text-xl lm:text-xl font-semibold">{props.text}</div>
                <a href='#' className='flex sm:mt-4 lm:mt-4'>
                    <h6 className="text-sm text-primary font-semibold mr-2">{t("Read More")}</h6>
                    <RightArrow fill={ primary } height="1.5rem" width="1.5rem" style={ {
                        transform: document.documentElement.dir === 'rtl' ? 'rotate(180deg)' : 'none',
                        transition: 'transform 0.3s ease',
                        height: document.documentElement.dir === 'rtl' ? '2rem' : '1.5rem',
                        width: document.documentElement.dir === 'rtl' ? '2rem' : '1.5rem',
                    } } />         
                </a>
            </div>
        </div>
    )
}