import * as React from "react"
const Star3 = ( props ) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={ 9 }
        height={ 9 }
        fill="none"
        { ...props }
    >
        <path
            fill="white"
            fillOpacity={ 0.2 }
            d="M6.356 7.643c.03 1.05-1.278 1.57-1.797.713l-.571-.943a.873.873 0 0 0-.68-.424l-1.65-.135C.85 6.787.55 5.746 1.158 5.117l1.241-1.283c.212-.22.33-.52.321-.821l-.034-1.157c-.03-1.05 1.278-1.57 1.797-.713l.571.943c.15.246.398.4.68.424l1.65.135c.809.067 1.109 1.108.5 1.737L6.644 5.665c-.212.22-.33.52-.32.821l.033 1.157Z"
        />
    </svg>
)
export default Star3
