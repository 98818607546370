import React, { useEffect, useState } from 'react';
import Navbar from "../Common/Navbar";
import Footer from '../Common/Footer';
import { primary } from "../Assets/Colors";
import { useTranslation } from "react-i18next";

export default function Integrations ()
{
    const { i18n, t } = useTranslation();
    const [ lang, setLang ] = useState( i18n.language );

    useEffect( () =>
    {
        const storedLang = localStorage.getItem( "lang" ) || "ar";
        console.log( "Stored Language:", storedLang ); 
        i18n.changeLanguage( storedLang );
        setLang( storedLang );
        document.documentElement.dir = storedLang === "ar" ? "rtl" : "ltr";
        console.log( "Document Direction:", document.documentElement.dir );
    }, [ i18n, lang ] );

    return (
        <div className="w-full">
            <Navbar bg="bg-white" text="text-black" logoFill="#333" btnbg={ primary } btntext="white" />
            <Footer />
        </div>
    )
}