import RightArrow from "../Assets/svgs/RightArrow"
import { primary } from "../Assets/Colors";

export default function Update ({img, title,date,text,link })
{
    return (
        <div className='w-full flex sm:flex-col lm:flex-col h-60 sm:h-130 lm:h-130 mx-auto my-8 hover:translate-x-1 transition-all duration-500'>
            <div className='w-2/5 sm:w-full lm:w-full flex items-center justify-center'>
                <img src={ img } alt="Image" className='h-52 sm:h-72 lm:h-96 w-[96%] sm:w-[100%] lm:w-[100%] cover rounded-3xl' />
            </div>
            <div className='w-3/5 sm:w-full lm:w-full flex flex-col items-start justify-center ps-2 sm:pt-4 lm:pt-4'>
                <h6 className='text-2.5xl font-semibold my-1'>{ title }</h6>
                <p className='text-sm sm:text-base lm:text-base tab:text-base my-1 text-medium'>{date}</p>
                <p className='text-sm sm:text-base lm:text-base tab:text-base my-2 text-medium'>{text}</p>
                <a href="#" className='text-sm text-primary font-semibold flex my-3'>
                    <span>{link}</span>
                    <RightArrow fill={ primary } height="1.2rem" width="1.2rem" />
                </a>
            </div>
        </div>
    )
}