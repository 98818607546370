import * as React from "react"
const Star2 = ( props ) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={ 11 }
        height={ 11 }
        fill="none"
        { ...props }
    >
        <path
            fill="white"
            fillOpacity={ 0.2 }
            d="M6.469 10.15c-.258.955-1.649.782-1.917-.239L4.01 7.857a1.193 1.193 0 0 0-.541-.714l-2.205-1.31c-.764-.454-.77-1.546-.01-1.811l2.192-.766a.834.834 0 0 0 .534-.58L4.5.753c.257-.956 1.649-.784 1.917.237l.54 2.055c.078.294.276.555.542.713l2.205 1.31c.764.455.77 1.547.01 1.812l-2.193.765a.834.834 0 0 0-.534.58L6.47 10.15Z"
        />
    </svg>
)
export default Star2
