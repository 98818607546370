import * as React from "react"
const RightArrow = ( props ) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        className="iconify iconify--ic"
        viewBox="0 0 24 24"
        { ...props }
    >
        <path fill={props.fill} d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
    </svg>
)
export default RightArrow
